// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-post-js": () => import("/vercel/workpath0/src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-pages-about-js": () => import("/vercel/workpath0/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("/vercel/workpath0/src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("/vercel/workpath0/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("/vercel/workpath0/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-new-js": () => import("/vercel/workpath0/src/pages/new.js" /* webpackChunkName: "component---src-pages-new-js" */),
  "component---src-pages-nuggetofjoy-js": () => import("/vercel/workpath0/src/pages/nuggetofjoy.js" /* webpackChunkName: "component---src-pages-nuggetofjoy-js" */),
  "component---src-pages-proposal-js": () => import("/vercel/workpath0/src/pages/proposal.js" /* webpackChunkName: "component---src-pages-proposal-js" */)
}

