module.exports = [{
      plugin: require('/vercel/workpath0/node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/vercel/workpath0/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/vercel/workpath0/node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"13","matomoUrl":"https://stats.jvt.me","siteUrl":"https://rizwanakhan.com/"},
    },{
      plugin: require('/vercel/workpath0/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"static/rizbizkits-logo.png"},
    }]
